<template>
  <div>
    <b-row class="match-height">
      <b-col
        md="12"
        lg="12"
      >
        <b-card no-body class="alerts-device">
          <b-card-body>
            <div class="alerts-info-top">
              <span class="alerts-icon">
                <feather-icon
                  icon="BellIcon"
                />
              </span>
              <span class="text-alerts">
                  Adequação ao uso
              </span>
              <div class="alerts-right d-none d-md-block">
                <b-form-group
                >
                  <v-select
                    class="date-select"
                    placeholder="day / week / month"
                  />
                </b-form-group>
              </div>
            </div>
          </b-card-body>
        </b-card>
        <b-card no-body class="alerts-table">
          <b-card-body>
            <b-row class="alerts-buttons">
              <b-col cols="4">
                <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    v-b-modal.modal-center
                    class="alerts-btn"
                    variant="none"
                  >
                    <span>Alerts</span>
                  </b-button>
              </b-col>
              <b-col cols="8" class="d-md-none d-block">
                <b-form-group
                >
                  <v-select
                    class="date-select"
                    placeholder="day / week / month"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <table class="table-alert d-none d-md-block">
              <tr>
                <td></td>
                <td class="table-title">Registo de ações</td>
                <td class="table-title">Início</td>
                <td class="table-title">Fim</td>
                <td></td>
              </tr>
              <tr class="table-body">
                <td class="alert-column">
                  <div class="table-icons">
                    <img class="icon-table" src="@/assets/images/icons/alert-red.png" alt="">
                    <div class="alert-text">
                      <span class="text-alert">
                        Temperatura ambiente<br/>
                        e Humidade relativa
                      </span>
                      <b-link class="alert-link">Ir para análise</b-link>
                    </div>
                  </div>
                </td>
                <td class="textarea-column">
                  <b-form-textarea
                    class="alert-textarea"
                  />
                </td>
                <td class="date-column">
                  <span>24-01-2022 10:40</span>
                </td>
                <td class="date-column">
                  <span>24-01-2022 11:35</span>
                </td>
                <td class="check-column">
                  <b-form-checkbox
                    value="A"
                  >
                  </b-form-checkbox>
                </td>
              </tr>
              <tr class="table-body">
                <td>
                  <div class="table-icons">
                    <img class="icon-table" src="@/assets/images/icons/alert-green.png" alt="">
                    <div class="alert-text">
                      <span class="text-alert">
                        Temperatura ambiente<br/>
                        e Humidade relativa
                      </span>
                      <b-link class="alert-link">Ir para análise</b-link>
                    </div>
                  </div>
                </td>
                <td>
                  <b-form-textarea
                    class="alert-textarea"
                  />
                </td>
                <td>
                  <span>24-01-2022 10:40</span>
                </td>
                <td>
                  <span>24-01-2022 11:35</span>
                </td>
                <td>
                  <b-form-checkbox
                    value="A"
                  >
                  </b-form-checkbox>
                </td>
              </tr>
              <tr class="table-body">
                <td>
                  <div class="table-icons">
                    <img class="icon-table" src="@/assets/images/icons/alert-icon.png" alt="">
                    <div class="alert-text">
                      <span class="text-alert">
                        Temperatura ambiente<br/>
                        e Humidade relativa
                      </span>
                      <b-link class="alert-link">Ir para análise</b-link>
                    </div>
                  </div>
                </td>
                <td>
                  <b-form-textarea
                    class="alert-textarea"
                  />
                </td>
                <td>
                  <span>24-01-2022 10:40</span>
                </td>
                <td>
                  <span>24-01-2022 11:35</span>
                </td>
                <td>
                  <b-form-checkbox
                    value="A"
                  >
                  </b-form-checkbox>
                </td>
              </tr>
            </table>
            <div class="table-subtitle">
              <div class="table-sub">
                <img class="icon-sub" src="@/assets/images/icons/nok.png" alt="">
                <span class="sub-text">Alerta ativo por resolver NOK</span>
              </div>
              <div class="table-sub">
                <img class="icon-sub" src="@/assets/images/icons/ups.png" alt="">
                <span class="sub-text">Alerta ativo por resolver UPS</span>
              </div>
              <div class="table-sub">
                <img class="icon-sub" src="@/assets/images/icons/ok.png" alt="">
                <span class="sub-text">Alerta resolvido</span>
              </div>
              <div class="table-sub">
                <feather-icon
                  class="alert-download"
                  icon="DownloadIcon"
                />
              </div>
              <div class="table-sub">
                <b-button
                  class="clear-btn"
                  variant="none"
                >
                  <span>Clear</span>
                </b-button>
              </div>
            </div>
            <b-row class="table-alert-mobile d-md-none d-flex">
              <b-col cols="2">
                <img class="icon-table" src="@/assets/images/icons/alert-red.png" alt="">
              </b-col>
              <b-col cols="8">
                <div class="alert-text">
                  <span class="text-alert">
                    Temperatura ambiente<br/>
                    e Humidade relativa
                  </span>
                  <b-link class="alert-link">Ir para análise</b-link>
                </div>
              </b-col>
              <b-col cols="2">
                <b-form-checkbox
                  value="A"
                >
                </b-form-checkbox>
              </b-col>
            </b-row>
            <b-row class="table-alert-mobile d-md-none d-flex">
              <b-col cols="12">
                <span class="table-title">Registo de ações</span>
                <b-form-textarea
                  class="alert-textarea"
                />
              </b-col>
            </b-row>
            <b-row class="table-alert-mobile d-md-none d-flex">
              <b-col cols="12" class="time-text">
                <span class="table-title">Início: 24-01-2022 10:40</span>
                <span class="table-title">Fim: 24-01-2022 11:35</span>
              </b-col>
            </b-row>
            <b-row class="table-alert-mobile d-md-none d-flex">
              <b-col cols="2">
                <img class="icon-table" src="@/assets/images/icons/alert-green.png" alt="">
              </b-col>
              <b-col cols="8">
                <div class="alert-text">
                  <span class="text-alert">
                    Temperatura ambiente<br/>
                    e Humidade relativa
                  </span>
                  <b-link class="alert-link">Ir para análise</b-link>
                </div>
              </b-col>
              <b-col cols="2">
                <b-form-checkbox
                  value="A"
                >
                </b-form-checkbox>
              </b-col>
            </b-row>
            <b-row class="table-alert-mobile d-md-none d-flex">
              <b-col cols="12">
                <span class="table-title">Registo de ações</span>
                <b-form-textarea
                  class="alert-textarea"
                />
              </b-col>
            </b-row>
            <b-row class="table-alert-mobile d-md-none d-flex">
              <b-col cols="12" class="time-text">
                <span class="table-title">Início: 24-01-2022 10:40</span>
                <span class="table-title">Fim: 24-01-2022 11:35</span>
              </b-col>
            </b-row>
            <b-row class="table-alert-mobile d-md-none d-flex">
              <b-col cols="2">
                <img class="icon-table" src="@/assets/images/icons/alert-icon.png" alt="">
              </b-col>
              <b-col cols="8">
                <div class="alert-text">
                  <span class="text-alert">
                    Temperatura ambiente<br/>
                    e Humidade relativa
                  </span>
                  <b-link class="alert-link">Ir para análise</b-link>
                </div>
              </b-col>
              <b-col cols="2">
                <b-form-checkbox
                  value="A"
                >
                </b-form-checkbox>
              </b-col>
            </b-row>
            <b-row class="table-alert-mobile d-md-none d-flex">
              <b-col cols="12">
                <span class="table-title">Registo de ações</span>
                <b-form-textarea
                  class="alert-textarea"
                />
              </b-col>
            </b-row>
            <b-row class="table-alert-mobile d-md-none d-flex">
              <b-col cols="12" class="time-text">
                <span class="table-title">Início: 24-01-2022 10:40</span>
                <span class="table-title">Fim: 24-01-2022 11:35</span>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12" class="table-subtitle-mobile">
                <div class="table-sub">
                  <feather-icon
                    class="alert-download"
                    icon="DownloadIcon"
                  />
                </div>
                <div class="table-sub">
                  <b-button
                    class="clear-btn"
                    variant="none"
                  >
                    <span>Clear</span>
                  </b-button>
                </div>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12" class="table-sub-mobile">
                <div class="table-sub">
                <img class="icon-sub" src="@/assets/images/icons/nok.png" alt="">
                <span class="sub-text">Alerta ativo por resolver NOK</span>
              </div>
              <div class="table-sub">
                <img class="icon-sub" src="@/assets/images/icons/ups.png" alt="">
                <span class="sub-text">Alerta ativo por resolver UPS</span>
              </div>
              <div class="table-sub">
                <img class="icon-sub" src="@/assets/images/icons/ok.png" alt="">
                <span class="sub-text">Alerta resolvido</span>
              </div>
              </b-col>
            </b-row>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
    <b-modal
      id="modal-center"
      centered
      ok-only
      ok-title=" Ótimo, vamos continuar!"
    >
      <div class="modal-text">
        <span class="modal-title">Delete Account</span>
        <span class="modal-description">
          Estamos tristes de o ver partir…<br/>
          Diga-nos porquê, todo feedback ajuda!
        </span>
      </div>
      <div class="modal-alert-body">
        <span class="modal-alert-title">Existem dois tipos de alertas:</span>
        <div class="modal-alerts">
          <img class="icon-modal" src="@/assets/images/icons/nok.png" alt="">
          <div class="modal-alert-text">
            <span class="modal-title-alert nok">NOK</span>
            <span class="modal-alert-description">algo seriamente errado, siga as recomendações rapidamente</span>
          </div>
        </div>
        <div class="modal-alerts">
          <img class="icon-modal" src="@/assets/images/icons/ups.png" alt="">
          <div class="modal-alert-text">
            <span class="modal-title-alert ups">UPS</span>
            <span class="modal-alert-description">algo não está perfeito, siga as recomendações para evitar futuros NOK</span>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import {
  BCard, BCardText, BButton, BRow, BCol, BImg, BCardBody, VBModal, BModal,
  BCardTitle, BCardSubTitle, BLink, VBTooltip, BFormTextarea, BFormCheckbox
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'

export default {
  components: {
    BModal,
    BCard,
    BCardText,
    BButton,
    BCardBody,
    BCardTitle,
    BCardSubTitle,
    BRow,
    BCol,
    BLink,
    BImg,
    BFormTextarea,
    BFormCheckbox,
    VBTooltip,
    vSelect,
  },
  directives: {
    'b-tooltip': VBTooltip,
    'b-modal': VBModal,
    Ripple,
  },
  data() {
    return {
      data: {},
    }
  },
}
</script>