<template>
  <section id="dashboard">
    <b-row class="match-height">
      <b-col
        lg="12"
        sm="12"
      >
        <alerts-info-abatedor />
      </b-col>
    </b-row>

    <b-row class="match-height">
      <b-col
        lg="12"
        sm="12"
      >
        <alerts-info />
      </b-col>
    </b-row>    
  </section>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'
import AlertsInfoAbatedor from './AlertsInfoAbatedor.vue'
import AlertsInfo from './AlertsInfo.vue'

export default {
  components: {
    BRow,
    BCol,
    AlertsInfoAbatedor,
    AlertsInfo,
  },
  data() {
    return {
      data: {},
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-alerts.scss';
</style>
