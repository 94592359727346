<template>
  <div>
    <b-row class="match-height">
      <b-col
        cols="12"
        xl="12"
      >
        <b-card no-body>
          <b-card-body>
            <div class="alerts-top">
              <b-card-title class="text-alerts mb-0">Abatedor e Ultracongelador Copa</b-card-title>
              <div class="alerts-right d-none d-md-block">
                <b-button
                  class="alerts-btn"
                  variant="none"
                >
                  <feather-icon
                    class="alerts-icon"
                    icon="CheckSquareIcon"
                  />
                  <span class="align-middle">Adequação ao uso</span>
                </b-button>
                <b-button
                  class="alerts-btn"
                  variant="none"
                >
                  <feather-icon
                    class="alerts-icon"
                    icon="CreditCardIcon"
                  />
                  <span class="align-middle">Envolvente</span>
                </b-button>
                <b-button
                  class="alerts-btn"
                  variant="none"
                >
                  <feather-icon
                    class="alerts-icon"
                    icon=""
                  />
                  <span class="align-middle">Performance</span>
                </b-button>
                <b-button
                  class="alerts-btn"
                  variant="none"
                  to="alerts"
                >
                  <feather-icon
                    class="alerts-icon"
                    icon="BellIcon"
                  />
                  <span class="align-middle">Alertas</span>
                </b-button>
                <b-button
                  class="alerts-btn"
                  variant="none"
                >
                  <feather-icon
                    class="alerts-icon"
                    icon="PieChartIcon"
                  />
                  <span class="align-middle">Relatórios</span>
                </b-button>
                <b-button
                  class="alerts-btn mr-0"
                  variant="none"
                >
                  <feather-icon
                    class="alerts-icon"
                    icon="CheckSquareIcon"
                  />
                  <span class="align-middle">Detalhes</span>
                </b-button>
              </div>
            </div>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
    <b-row>
      <b-col
        cols="12"
        xl="4"
      >
        <b-card no-body>
          <b-card-body>
            <div class="alerts-img">
              <img src="https://fakeimg.pl/525x251/" alt="">
            </div>
          </b-card-body>
        </b-card>
      </b-col>
      <b-col
        cols="12"
        xl="8"
      >
        <b-card no-body class="alerts-status">
          <b-card-body>
            <b-row>
              <b-col xl="4">
                <b-card-title class="text-status mb-0">Device status</b-card-title>
                <div class="alert-device-icons">
                  <div class="alert-icon-device">
                    <img src="@/assets/images/icons/ups.png" alt="" width="60" height="61">
                    <span class="alert-device-text">ups</span>
                  </div>
                </div>
              </b-col>
              <b-col class="status-side" xl="4">
                <b-card-title class="text-status mb-0">Receita em uso</b-card-title>
                <div class="alert-receita">
                  <div class="alert-receita-icon">
                    <img src="https://fakeimg.pl/151x151" alt="" width="60" height="61">
                    <span>Carne vermelha</span>
                  </div>
                </div>
              </b-col>
              <b-col class="status-side" xl="4">
                <b-card-title class="text-status mb-0">Temperatura</b-card-title>
                <div class="alert-temps">
                  <div class="alert-temp">
                      <vue-apex-charts
                        type="radialBar"
                        height="300"
                        :options="goalOverviewRadialBar.chartOptions"
                        :series="goalOverviewRadialBar.series"
                      />
                      <div class="temp-range">
                          <span>
                            {{ goal_overview.min_temp }}
                          </span>
                          <span>
                            {{ goal_overview.max_temp }}
                          </span>
                      </div>
                  </div>
                </div>
                <div class="info-btn">
                  <b-button
                    class="btn-sm temp-info-btn"
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    v-b-tooltip.hover.left="'Custo e tempo total que o modo de conservação esteve ativo após concluído um ciclo. Para máx. eficiência reduza este valor.'"
                    variant="none"
                  >
                    <feather-icon
                      class="temp-info-icon"
                      icon="AlertCircleIcon"
                    />
                  </b-button>
                </div>
              </b-col>
            </b-row>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BCard, BCardText, BButton, BRow, BCol, BImg, BCardBody, BCardTitle, 
  BCardSubTitle, BLink, VBTooltip
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import VueApexCharts from 'vue-apexcharts'

export default {
  components: {
    VueApexCharts,
    BCard,
    BCardText,
    BButton,
    BCardBody,
    BCardTitle,
    BCardSubTitle,
    BRow,
    BCol,
    BLink,
    BImg,
    VBTooltip,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  data() {
    return {
      goal_overview: {
        min_temp: '0,0°',
        max_temp: '3,0°',
      },
      goalOverviewRadialBar: {
        series: [50],
        chartOptions: {
          chart: {
            sparkline: {
              enabled: true,
            },
            dropShadow: {
              enabled: true,
              blur: 3,
              left: 1,
              top: 1,
              opacity: 0.1,
            },
          },
          colors: ['#fff'],
          plotOptions: {
            radialBar: {
              offsetY: 0,
              startAngle: -90,
              endAngle: 90,
              hollow: {
                size: '50%',
              },
              track: {
                background: 'rgba(255, 255, 255, 0.5)',
                strokeWidth: '100%',
              },
              dataLabels: {
                name: {
                  offsetY: 30,
                  color: '#fff',
                  fontSize: '18px',
                },
                value: {
                  offsetY: -15,
                  color: '#fff',
                  fontSize: '29px',
                },
              
              },
            },
          },
          fill: {
            colors: '#fff'
          },
          stroke: {
            lineCap: 'square',
          },
          grid: {
            padding: {
              bottom: 30,
            },
          },
          labels: [''],
        },
      },
    }
  },
}
</script>
